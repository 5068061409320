import { createApp } from "vue";
import { Vue3Mq } from "vue3-mq";
import App from "./App.vue";
const VueScrollTo = require('vue-scrollto');

import "../scss/style.scss";

//----------------------------------------------------------------------
//  読み込み
//----------------------------------------------------------------------
const app = createApp(App);
app.use(Vue3Mq);
app.use(VueScrollTo);

app.mount("#app");

//----------------------------------------------------------------------
//  splide
//----------------------------------------------------------------------
// デフォルトのテーマ
import '@splidejs/splide/css';

// または、そのほかのテーマ
import '@splidejs/splide/css/skyblue';
import '@splidejs/splide/css/sea-green';

// あるいは、コアスタイルのみ
import '@splidejs/splide/css/core';

import { Splide } from '@splidejs/splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';

var elms = document.getElementsByClassName( 'splide' );

for ( var i = 0; i < elms.length; i++ ) {
  let options = {
    type: 'loop',
    drag: true,
    focus: 'center',
    autoWidth: true,
    pagination: false,
    arrows: false,
    gap: '1rem',
    direction: 'ltr',
    autoScroll: {
        speed: 0.5,
        rewind: false,
        pauseOnHover: false,
        pauseOnFocus: false
    },
    breakpoints: {
        767.2: {
            destroy: false,
        }
    }
  };

  if (i === 0) {
    if (window.innerWidth <= 767) {
      options.focus = 1;
    } else {
      options.focus = 'center';
    }
  }

  if (i === 2) {
    if (window.innerWidth <= 767) {
      options.type = 'loop';
    } else {
      options.type = 'slide';
    }
  }

  new Splide( elms[ i ], options ).mount({ AutoScroll });
}

<script>
// wow.js
const WOW = require('wow.js');
import 'animate.css';

// vue components
import gotop from './components/Gotop.vue';
import aco from './components/Aco.vue';
import aco02 from './components/Aco02.vue';
// vue components（フォーム関係）
import btnsubmit from './components/BtnSubmit.vue'; //送信ボタン
import formdeleteinvalid from './components/FormDeleteInvalid.vue'; //入力があったらinvalidクラス削除

export default {
    inject: ["mq"],

    components: {
        gotop,
        aco,
        aco02,
        btnsubmit,
        formdeleteinvalid,
    },

    data() {
        return {
            headerH: 0, //ヘッダーの高さ
            gnavActive: false, //グロナビアクティブ管理
            isFixed: false, // ヘッダー固定状態
            isFade: false, // ヘッダー固定状態
            isTransition: false, // ヘッダー固定状態
        }
    },

    methods: {
        // wow.js初期化
        wowInit() {
            const wow = new WOW({
                offset: this.mq.mdMinus ? 100 : 300, //SP : PC
                animateClass: 'animate__animated',
                mobile: true,
            });
            wow.init();
        },

        // ヘッダーの高さを取得
        getHeaderH() {
            const header = this.$refs.header;

            const func = () => {
                this.headerH = header.getBoundingClientRect().height;
            }

            window.addEventListener('load', func);
            window.addEventListener('resize', func);
        },

        // スクロール位置を監視してヘッダーにクラスを付与
        handleScroll() {
            const targetElement = document.querySelector('.target-element');
            if (targetElement) {
                const targetPosition = targetElement.getBoundingClientRect().top + window.scrollY;
                const scrollPosition = window.scrollY;
                this.isFixed = scrollPosition > 700;
                this.isTransition = scrollPosition > 750;
                this.isFade = scrollPosition >= targetPosition; // 特定のクラス名の要素に到達したら固定
            }
        },

        // アンカーリンクアニメーション
        scrollToAnchor(event) {
            event.preventDefault();
            const targetId = event.currentTarget.getAttribute('href').substring(1);
            const targetElement = document.getElementById(targetId);
            const headerHeight = this.headerH;

            if (targetElement) {
                const targetPosition = targetElement.getBoundingClientRect().top + window.scrollY - headerHeight;
                window.scrollTo({
                    top: targetPosition,
                    behavior: 'smooth'
                });
            }
        }
    },

    mounted() {
        this.wowInit();
        this.getHeaderH();
        window.addEventListener('scroll', this.handleScroll);

        // アンカーリンクにイベントリスナーを追加
        document.querySelectorAll('a[href^="#"]').forEach(anchor => {
            anchor.addEventListener('click', this.scrollToAnchor);
        });
    },

    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
        
        // イベントリスナーを削除
        document.querySelectorAll('a[href^="#"]').forEach(anchor => {
            anchor.removeEventListener('click', this.scrollToAnchor);
        });
    }

}
</script>